import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, Confirm} from 'react-admin'
import Button from '@material-ui/core/Button'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = (theme) => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class OrderStatusButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isOpenSent: false,
      isOpenCancel: false,
      isOpenForce: false,
      value: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSentConfirm = this.handleSentConfirm.bind(this)
    this.handleSentCancel = this.handleSentCancel.bind(this)
    this.handleCancelConfirm = this.handleCancelConfirm.bind(this)
    this.handleCancelCancel = this.handleCancelCancel.bind(this)
    this.handleForceConfirm = this.handleForceConfirm.bind(this)
    this.handleForceCancel = this.handleForceCancel.bind(this)
    this.updateReview = this.updateOrder.bind(this)
  }

  async updateOrder(delivery) {
    const {dispatchCrudGetOne, basePath, record, selectedIds} = this.props
    const value = this.state.value === 'changeSent' ? 'sent' : this.state.value
    await dataProvider('UPDATE_STATUS', 'orders20', {
      id: record.id,
      data: {productOrderIds: selectedIds, status: value, ...delivery}
    })

    dispatchCrudGetOne('orders20', record.id, basePath)
  }

  handleClick() {
    this.setState({
      isOpen: true,
      isOpenCancel: false,
      isOpenForce: false,
      value: null
    })
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    const {record} = this.props
    const {value} = this.state
    if (record.status === value) {
      return this.setState({isOpen: false})
    } else if (value === 'cancelled') {
      return this.setState({isOpenCancel: true})
    } else if (value === 'force') {
      return this.setState({isOpenForce: true})
    } else {
      await this.updateOrder()
    }
    this.setState({isOpen: false})
  }

  async handleSentConfirm(delivery) {
    await this.updateOrder(delivery)
    this.setState({isOpen: false, isOpenSent: false})
  }

  handleSentCancel() {
    this.setState({isOpenSent: false})
  }

  async handleCancelConfirm() {
    const {basePath, record, dispatchCrudDelete, dispatchCrudGetOne} = this.props
    dispatchCrudDelete('orders20', record.id, record, basePath)
    this.setState({isOpen: false})
    dispatchCrudGetOne('orders20', record.id, basePath)
  }

  handleCancelCancel() {
    this.setState({isOpenCancel: false})
  }

  async handleForceConfirm() {
    const {basePath, record, dispatchCrudGetOne} = this.props
    await dataProvider('DELETE_FORCE', 'orders20', {id: record.id})
    this.setState({isOpen: false, isOpenForce: false})
    dispatchCrudGetOne('orders20', record.id, basePath)
  }

  handleForceCancel() {
    this.setState({isOpenForce: false})
  }

  handleChange(e, v) {
    this.setState({value: v})
  }

  render() {
    const {isOpen} = this.state
    const {classes, translate, record, selectedIds} = this.props
    const choices = [
      {id: 'cancelled', name: '주문취소'},
      {id: 'refund', name: '환불'}
    ]

    return record.status !== 'cancelled' ? (
      <div>
        <AdminButton disabled={!selectedIds.length} label="주문" onClick={this.handleClick}>
          <ShoppingCartIcon />
        </AdminButton>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onEntering={this.handleEntering}
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">주문 상태 변경</DialogTitle>
          <DialogContent>
            <RadioGroup
              ref={(ref) => {
                this.radioGroupRef = ref
              }}
              aria-label="Status"
              name="status"
              value={this.state.value}
              onChange={this.handleChange}
            >
              {choices.map(({id, name}) => (
                <FormControlLabel value={id} key={id} control={<Radio />} label={name} />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={this.state.isOpenCancel}
          confirmColor="warning"
          title={'주문취소'}
          content={'정말로 주문을 취소하시겠습니까?'}
          cancel={translate('ra.action.cancel')}
          confirm={translate('ra.action.confirm')}
          onConfirm={this.handleCancelConfirm}
          onClose={this.handleCancelCancel}
        />
        <Confirm
          isOpen={this.state.isOpenForce}
          style={{whiteSpace: 'pre'}}
          confirmColor="warning"
          title={'강제취소'}
          content={`정말로 주문을 강제 취소 취소하시겠습니까? 강제 취소 시 결제 취소는 되지 않습니다.`}
          cancel={translate('ra.action.cancel')}
          confirm={translate('ra.action.confirm')}
          onConfirm={this.handleForceConfirm}
          onClose={this.handleForceCancel}
        />
      </div>
    ) : (
      ''
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetOne: crudGetOne,
    dispatchCrudDelete: crudDelete
  }),
  translate,
  withStyles(styles)
)(OrderStatusButton)
