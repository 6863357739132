import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {TextInput} from 'ra-ui-materialui'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
})

function TimePickers(props) {
  const {classes, source, label} = props

  return (
    <TextInput
      source={source}
      label={label}
      type="time"
      className={classes.textField}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300
      }}
    />
  )
}

TimePickers.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TimePickers)
