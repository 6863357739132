import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'

import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const PointListSearchExcelExportButton = (props) => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      const {filterValues} = props
      // 여기에 엑셀 정보를 차곡차곡 쌓음, 첫 array는 엑셀의 제목
      setLoading(true)
      const dataArr = [['ID', '사용자 이름', '종류', '포인트', '남은 포인트', '내용', '기타', '소멸 날짜', '생성일']]
      const {
        data: {data}
      } = await dataProvider('EXCEL', 'points', {
        sort: {field: 'id', order: 'DESC'},
        filter: filterValues
      })

      data.forEach((pt, index) => {
        try {
          dataArr.push([
            pt.id,
            pt.userId,
            pt.type,
            pt.point,
            pt.remainPoint,
            pt.description,
            pt.etc,
            new Date(pt.expireAt).toLocaleString(),
            new Date(pt.createdAt).toLocaleString()
          ])
        } catch (e) {
          console.log(e)
        }
      })

      setLoading(false)
      excelDownload(dataArr, `필터 적용된 포인트 내역 - ${new Date().toLocaleString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="필터 적용된 포인트 내역 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default PointListSearchExcelExportButton
