import BookIcon from '@material-ui/icons/Book'
import FaciltieCreate from './PlaceBreedCreate'
import FaciltieEdit from './PlaceBreedEdit'
import FaciltieShow from './PlaceBreedShow'
import FaciltieList from './PlaceBreedList'

export default {
  list: FaciltieList,
  create: FaciltieCreate,
  edit: FaciltieEdit,
  show: FaciltieShow,
  icon: BookIcon
}
