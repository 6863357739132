import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, Datagrid, DateField, List, NumberField, BooleanField, SelectField} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath}) => {
  return <CardActions></CardActions>
}

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} bulkActions={false}>
      <Datagrid rowClick="show">
        <NumberField source="id" sortable={false} />
        <SelectField
          source="name"
          choices={[
            {id: 'profile', name: '프로필 영역'},
            {id: 'walkStatus', name: '산책 영역'},
            {id: 'sharingInformation', name: '공지사항 영역 (초기 기획 : 정보 공유 영역)'},
            {id: 'events', name: '이벤트 영역'},
            {id: 'seedCampaigns', name: '체험단 영역'},
            {id: 'communityPopular', name: '커뮤니티 인기글 영역'},
            {id: 'communityWalks', name: 'live 산책인증 영역'},
            {id: 'banner', name: '배너 영역'},
            {id: 'recommendProducts', name: '추천 상품 영역'},
            {id: 'recommendPlaces', name: '추전 장소 영역'},
            {id: 'nearbyPlaces', name: '주변 장소 영역'},
            {id: 'offerwalls', name: '클릭 보너스'},
            {id: 'pointCharging', name: '포인트 충전소 영역'},
            {id: 'jointPurchase', name: '공동구매'}
          ]}
        />
        <BooleanField source="display" sortable={false} />
        <DateField source="updatedAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
