import React from 'react'

import {DateField, Show, SimpleShowLayout, TextField, translate, SelectField, BooleanField} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from '../../custom/common/ImageClickField'

class KnowledgeShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <BooleanField label="베스트 공감" source="isActive" />

          <TextField source="title" />
          <TextField source="subTitle" />
          <TextField source="content" />
          <SelectField
            source="type"
            choices={[
              {id: 'text', name: 'text'},
              {id: 'url', name: 'url'}
            ]}
          />
          <ImageClickField label="대표 이미지" addLabel={true} source="thumbnailPath" />
          <ImageClickField label="상세 이미지" addLabel={true} source="images" />
          <DateField source="createdAt" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(KnowledgeShow)
