import BookIcon from '@material-ui/icons/Book'
import RedeemCreate from './RedeemCreate'
import RedeemEdit from './RedeemEdit'
import RedeemShow from './RedeemShow'
import RedeemList from './RedeemList'

export default {
  list: RedeemList,
  create: RedeemCreate,
  edit: RedeemEdit,
  show: RedeemShow,
  icon: BookIcon
}
