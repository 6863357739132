import React from 'react'

import {NumberField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'

class BreedShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <NumberField source="order" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(BreedShow)
