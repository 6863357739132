import BookIcon from '@material-ui/icons/Book'
import NoticeCreate from './NoticeCreate'
import NoticeEdit from './NoticeEdit'
import NoticeShow from './NoticeShow'
import NoticeList from './NoticeList'

export default {
  list: NoticeList,
  create: NoticeCreate,
  edit: NoticeEdit,
  show: NoticeShow,
  icon: BookIcon
}
