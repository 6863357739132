import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  SelectField,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = (props) => {
  const permissions = localStorage.getItem('auth')
  return <CardActions>{permissions === 'master' ? <CreateButton basePath={props.basePath} /> : null}</CardActions>
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={props.translate('resources.users.fields.name')} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({permissions, classes, ...props}) => {
  return (
    <List
      {...props}
      sort={{field: 'id', order: 'DESC'}}
      actions={<Actions />}
      perPage={25}
      filters={<ListFilter />}
      bulkActions={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <SelectField
          source="auth"
          choices={[
            {id: 'admin', name: '관리자'},
            {id: 'manager', name: '매니저'},
            {id: 'master', name: '마스터'}
          ]}
          sortable={false}
        />
        <TextField source="name" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
