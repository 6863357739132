import React from 'react'
import {DateField, Edit, SelectInput, SimpleForm, TextInput} from 'react-admin'
import {password, required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={required} />
      <TextInput source="password" type="password" validate={[required, password]} />
      {/*<TextInput source="adminName" validate={required}/>*/}

      <SelectInput
        source="auth"
        choices={[
          {id: 'master', name: '마스터'},
          {id: 'admin', name: '관리자'},
          {id: 'manager', name: '매니저'}
        ]}
      />

      <DateField source="createdAt" locales="ko" showTime />
    </SimpleForm>
  </Edit>
)
