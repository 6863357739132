import React, {useEffect, useState} from 'react'
import ChartJS from 'chart.js'

const Chart = ({canvasId, label, data, stack}) => {
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (label) {
      const options = {
        maintainAspectRatio: false,
        spanGaps: false,
        elements: {
          line: {
            tension: 0.000001
          }
        },
        plugins: {
          filler: {
            propagate: false
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 0
            }
          }
        }
      }

      const chartjs = new ChartJS('chart-' + canvasId, {
        type: 'line',
        data: {
          labels: Array.from({length: 31}, (_, i) => `D-${i}`),
          datasets: [
            {
              backgroundColor: '#FFF',
              borderColor: stack ? '#293793' : '#FF6384',
              data: [],
              label: stack ? '누적' : '당일',
              fill: false
            }
          ]
        },
        options: ChartJS.helpers.merge(options, {
          title: {
            text: `${label}(${stack ? '누적' : '당일'})`,
            display: true
          }
        })
      })
      setChart(chartjs)
    }
  }, [canvasId, label, stack])
  useEffect(() => {
    if (chart) {
      const today = () => {
        let todayData = []
        if (data) {
          todayData = data.data.map((day, index) => {
            if (label === '당일 구매자 수') return {x: index, y: day.users}
            if (label === '매출 구매액') return {x: index, y: day.purchase}
            return {x: index, y: day.count}
          })
        }
        return todayData
      }

      const total = () => {
        let totalData = []
        if (data) {
          let total
          if (label === '당일 구매자 수') total = data.data[0].totalUsers
          else if (label === 'Posting 고객 수') total = data.data[0].total
          else if (label === '산책 고객 수') total = data.data[0].total
          else if (label === '매출 구매액') total = data.totalPurchase
          else total = data.total
          for (let i = 0; i < data.data.length; i++) {
            if (i === 0) totalData.push({x: i, y: total})
            else {
              if (label === '당일 구매자 수') total = data.data[i].totalUsers
              else if (label === '매출 구매액') total = total - data.data[i - 1].purchase
              else if (label === '산책 고객 수') total = data.data[i].total
              else if (label === 'Posting 고객 수') total = data.data[i].total
              else total = total - data.data[i - 1].count
              totalData.push({x: i, y: total})
            }
          }
        }
        return totalData
      }
      stack ? (chart.config.data.datasets[0].data = total()) : (chart.config.data.datasets[0].data = today())
      chart.update()
    }
  }, [data, chart, stack, label])

  return (
    <>
      <canvas key={`chart-${canvasId}`} id={`chart-${canvasId}`} />
    </>
  )
}
export default Chart
