import React, {useState} from 'react'
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  ImageField,
  TextInput,
  maxLength,
  minLength,
  minValue,
  maxValue,
  regex,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm
} from 'react-admin'

import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput
          source="character"
          choices={[
            {id: 'siru', name: '펫피 시루'},
            {id: 'maru', name: '펫피 마루'},
            {id: 'haru', name: '펫피 하루'}
          ]}
          validate={required}
        />

        <TextInput source="title" multiline validate={[required, maxLength(50)]} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) {
              formData.images = imageData
            }
            return (
              <OrderImageInput
                label="이미지"
                placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                multiple
                setState={setImageData}
                source="images"
                accept="image/*"
              >
                <ImageField source="image" title="title" />
              </OrderImageInput>
            )
          }}
        </FormDataConsumer>

        <SelectInput
          source="type"
          choices={[
            {id: 'myPage', name: '마이 페이지'},
            {id: 'image', name: '이미지'},
            {id: 'url', name: 'URL'},
            {id: 'walk', name: '산책'},
            {id: 'events', name: '기획전'}
          ]}
          validate={required}
        />

        <FormDataConsumer>
          {({formData, ...rest}) => {
            return (
              <>
                {formData && formData.type === 'events' ? (
                  <ReferenceInput
                    label="기획전 ID"
                    source="exhibitionId"
                    reference="exhibition"
                    validate={required}
                    perPage={200}
                  >
                    <SelectInput optionText="title" />
                  </ReferenceInput>
                ) : formData && formData.type === 'url' ? (
                  <div>
                    <TextInput source="url" multiline validate={[maxLength(180)]} />
                    <TextInput
                      label="광고 파라미터"
                      source="adUrl"
                      validate={[maxLength(20), regex(/^[a-zA-Z]*$/, '영문자만 가능합니다.')]}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )
          }}
        </FormDataConsumer>

        <TextInput source="content" multiline validate={[required, minLength(1), maxLength(2000)]} />
        <NumberInput source="order" defaultValue={1000} validate={[required, minValue(1), maxValue(1000)]} />
        <BooleanInput source="onOff" validate={[required]} defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}
