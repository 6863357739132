import React from 'react'

import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'

class RedeemShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />
            <ReferenceField source="sellerId" reference="sellers" allowEmpty linkType={'show'} perPage={50}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="title" />
            <NumberField source="point" />
            <DateField source="startDate" locales="ko" />
            <DateField source="endDate" locales="ko" />
            <DateField source="createdAt" showTime locales="ko" />
          </Tab>
          <Tab label={'코드 정보'}>
            <ArrayField source="codes.data" label={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="code" sortable={false} />
                <TextField source="usedCount" sortable={false} />
                <DateField source="startDate" sortable={false} locales="ko" />
                <DateField source="endDate" sortable={false} locales="ko" />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(RedeemShow)
