import Jimp from 'jimp'
import {dataProvider} from '../../App'

const axios = require('axios')

const imageHandler = (file, subKey, type, objectKey) =>
  new Promise(async (resolve, reject) => {
    const rawFile = subKey ? file[subKey].rawFile : file.rawFile
    let data
    try {
      const resizeImage = await Jimp.read(rawFile.preview)
      await resizeImage.resize(300, 300)
      data = await resizeImage.getBufferAsync(rawFile.type)
    } catch (e) {
      //images
    }
    if (type === 'places' && objectKey === 'images') {
      getPreSignedUrl(type, rawFile.type, data, objectKey)
        .then((result) => putObject(result, rawFile, data, objectKey, type))
        .then((info) => resolve({image: info.path, thumbnail: info.thumbPath}))
        .catch((e) => reject(e))
    } else {
      getPreSignedUrl(type, rawFile.type, data, objectKey, type)
        .then((result) => putObject(result, rawFile, data, objectKey, type))
        .then((info) => resolve({image: info.path}))
        .catch((e) => reject(e))
    }
  })

async function getPreSignedUrl(type, mimeType, data, objectKey) {
  try {
    let size = 'large'
    if (objectKey === 'thumbnailPath') size = 'thumbnail'
    const {
      data: {url, path}
    } = await dataProvider('UPLOAD', 'files', {data: {type, mimeType, size}})
    let thumbUrl
    let thumbPath
    if (type === 'places') {
      const size = 'thumbnail'
      const thumb = await dataProvider('UPLOAD', 'files', {data: {type, mimeType, size}})
      thumbUrl = thumb.data.url
      thumbPath = thumb.data.path
    }
    return {url, path, thumbUrl, thumbPath, data}
  } catch (e) {
    throw e
  }
}

async function putObject(info, file, data, objectKey, type) {
  if ((type === 'knowledge' || type === 'reviews') && objectKey === 'images') file = file.preview
  if (
    (type === 'products' || type === 'seller') &&
    (objectKey === 'detailImages' || objectKey === 'returnPolicy' || objectKey === 'exchangePolicy')
  ) {
    file = file.preview
  }
  try {
    if (objectKey === 'thumbnailPath') await axios.put(info.url, data, {headers: {'Content-Type': file.type}})
    else {
      await axios.put(info.url, file, {headers: {'Content-Type': file.type}})
    }
    if (objectKey === 'images' && type === 'places')
      await axios.put(info.thumbUrl, data, {headers: {'Content-Type': file.type}})
    return info
  } catch (e) {
    throw e
  }
}

export function convertImagePath(path, size) {
  const substr = path.substring(0, 6)
  if (substr === 'images') path = `${process.env.REACT_APP_URL}/images/${encodeURIComponent(path)}?size=${size}`
  return path
}

export async function uploadToS3(data, key, type, objectKey, subKey) {
  if (!data[key]) return data
  const arr = data[key]
  if (!Array.isArray(arr)) {
    if (arr.id) return data
    if (!arr.rawFile) {
      if (!data[key].rawFile) return data
      delete data[key]
      return data
    }
    return imageHandler(arr, subKey, type, objectKey).then((ret) => {
      data[key] = ret
      return data
    })
  }
  if (arr && arr.length && key !== 'viewOptions') {
    const formerFiles = arr
      .filter((f) => {
        const rawFile = subKey ? f[subKey].rawFile : f.rawFile
        if (rawFile) {
          if (typeof rawFile.preview === 'object') return false
        }
        return !(rawFile instanceof File)
      })
      .map((file) => {
        const data = {...file}
        delete data[subKey]
        return data
      })
    const newFiles = arr.filter((f) => {
      const rawFile = subKey ? f[subKey].rawFile : f.rawFile
      if (rawFile) {
        if (typeof rawFile.preview === 'object') return rawFile
      }
      return rawFile instanceof File
    })
    const transformedFiles = []
    for (let i = 0; i < newFiles.length; i++) {
      const f = newFiles[i]
      const uploaded = await imageHandler(f, subKey, type, objectKey)
      transformedFiles.push(uploaded)
    }
    data[key] = [...formerFiles, ...transformedFiles]
  }
  if (arr && arr.length && key === 'viewOptions') {
    for (const [index, item] of arr.entries()) {
      const formerFiles = Array.isArray(item[objectKey])
        ? item[objectKey]
        : [item[objectKey]]
            .filter((f) => {
              const rawFile = subKey ? f[subKey].rawFile : f.rawFile
              if (rawFile) {
                if (typeof rawFile.preview === 'object') return false
              }
              return !(rawFile instanceof File)
            })
            .map((file) => {
              const data = {...file}
              delete data[subKey]
              return data
            })
      const newFiles = Array.isArray(item[objectKey])
        ? item[objectKey]
        : [item[objectKey]].filter((f) => {
            const rawFile = subKey ? f[subKey].rawFile : f.rawFile
            if (rawFile) {
              if (typeof rawFile.preview === 'object') return rawFile
            }
            return rawFile instanceof File
          })
      const transformedFiles = []
      if (Array.isArray(newFiles) && !newFiles[0].path) {
        for (let i = 0; i < newFiles.length; i++) {
          const f = newFiles[i]
          const uploaded = await imageHandler(f, subKey, type, objectKey)
          transformedFiles.push(uploaded)
        }
      }
      if (formerFiles.length > 0) {
        item.urlImage = formerFiles[0].path
      } else {
        item.urlImage = transformedFiles[0].image
      }
      item.type = 'viewMultiCtaButton'
    }
  }
  return data
}
