import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {BooleanField, CardActions, Datagrid, DateField, List, NumberField, SelectField} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath}) => <CardActions />

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} bulkActions={false}>
      <Datagrid rowClick="show">
        <NumberField source="id" sortable={false} />
        <SelectField
          source="type"
          choices={[
            {id: 'greenpSdk', name: '애드포러스 오퍼월'},
            {id: 'tnkSdk', name: 'TNK'},
            {id: 'pincruxSdk', name: '핀크럭스'},
            {id: 'greenpMobileWeb', name: '애드포러스 웹뷰 오퍼월'},
            {id: 'gmoMobileWeb', name: 'GMO'}
          ]}
        />
        <BooleanField source="display" sortable={false} />
        <DateField source="updatedAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
