import React, {useState} from 'react'
import {
  Create,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  maxLength,
  minLength,
  SimpleForm,
  TextInput
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput multiline label="제목" source="title" validate={[required, maxLength(50)]} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) {
              formData.images = imageData
            }
            return (
              <OrderImageInput
                label="이미지"
                placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                multiple
                setState={setImageData}
                source="images"
                accept="image/*"
              >
                <ImageField source="image" title="title" />
              </OrderImageInput>
            )
          }}
        </FormDataConsumer>
        <RichTextInput
          style={{whiteSpace: 'pre-wrap'}}
          label="내용"
          source="content"
          validate={[required, minLength(1), maxLength(3000)]}
        />
        <DateTimeInput label="공지일" source="createdAt" validate={required} defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  )
}
