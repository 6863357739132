import React, {useState} from 'react'
import {
  TextField,
  BooleanInput,
  Create,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  maxLength,
  SelectInput,
  SimpleForm,
  ImageInput,
  NumberInput,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput source="type" choices={[{id: 'notice', name: '공지사항'}]} validate={required} />
        <TextInput source="title" multiline validate={[required, maxLength(150)]} />
        <TextInput source="content" multiline validate={[required]} />
        <NumberInput source="rolling_order" validate={[required]} />
        <TextField label="롤링을 원하지 않으시거나 종료를 원하시면 0, 롤링을 원하시면 해당 기간내에 다른 공지사항이랑 겹치지않는지 확인하시고 0 이외에 다른 숫자를 입력해주세요." />
        <TextInput source="pushTitle" multiline defaultValue={'-'} validate={[required, maxLength(150)]} />

        <ImageInput source="titleImagePath" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) {
              formData.images = imageData
            }
            return (
              <>
                <OrderImageInput
                  label="상세 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setImageData}
                  source="images"
                  accept="image/*"
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>
              </>
            )
          }}
        </FormDataConsumer>
        <BooleanInput source="push" validate={[required]} defaultValue={false} />
        <TextField label="예약할 푸시 시간은 현재(푸시 업로드 시점)보다 2시간 이후이어야 등록되며, 2시간 이내이면 즉시 푸시됩니다. 또한, 각 푸시 발송 시간은 2시간 이상 차이가 나야 정상 등록됩니다." />
        <DateTimeInput
          label="푸시 예약 발송 일시"
          source="pushDateTime"
          defaultValue={new Date('2021-05-01 12:00:00')}
        />

        <DateInput label="시작 날짜" source="startDate" />
        <DateInput label="종료 날짜" source="endDate" />

        <DateTimeInput source="periodStart" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="periodEnd" locales="ko" validate={[required]} defaultValue={new Date()} />

        <DateTimeInput source="createdAt" validate={required} defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  )
}
