import React from 'react'
import {BooleanInput, Edit, maxLength, minLength, NumberInput, SimpleForm, TextInput} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import {required} from '../../validators'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={[required, maxLength(50), minLength(1)]} />
      <BooleanInput source="recommended" validate={required} />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
)
