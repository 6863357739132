import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  SearchInput,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={'제목'} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ReferenceField
          source="sellerId"
          reference="sellers"
          allowEmpty
          linkType={'show'}
          sortable={false}
          perPage={50}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="title" sortable={false} />
        <NumberField source="point" sortable={false} />
        <DateField source="startDate" locales="ko" sortable={false} />
        <DateField source="endDate" locales="ko" sortable={false} />
      </Datagrid>
    </List>
  )
})
