import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
  ImageField,
  Filter,
  SearchInput,
  NumberField,
  BooleanField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="아이디" source="searchId" alwaysOn />
    <SearchInput label={props.translate('resources.reports.fields.title')} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ThumbnailImageField source="thumbnailPath" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="subTitle" sortable={false} />
        <NumberField source="totalReadCount" sortable={false} />
        <NumberField source="readCount" sortable={false} />
        <BooleanField source="isAdOn" />
        {/*<NumberField source='totalLikeCount'sortable={false}/>*/}
        {/*<NumberField source='likeCount'sortable={false}/>*/}
        {/*<NumberField source='totalCommentCount'sortable={false}/>*/}
        {/*<NumberField source='commentCount'sortable={false}/>*/}
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
