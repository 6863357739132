import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  SelectInput,
  DateField,
  TextField
} from 'react-admin'
import choices from './choices'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" label="내용 검색" alwaysOn />
    <SelectInput source="period" label="기간 선택" choices={choices.period} alwaysOn />
    {/*<DateInput source="startDate" label='시작 날짜' alwaysOn/>
        <DateInput source="endDate" label='종료 날짜' alwaysOn/>
        */}
  </Filter>
)

const RedTextField = ({record, source}) => <span style={{color: 'red'}}>{record[source]}</span>

export default withStyles(styles)(({classes, history, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} label="ID" />
        <RedTextField source="isNew" sortable={false} label="" />
        <TextField source="title" sortable={false} label="제목" />
        <DateField source="createdAt" locales="ko" label="공지일" showTime sortable={true} />
      </Datagrid>
    </List>
  )
})
