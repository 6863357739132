import BookIcon from '@material-ui/icons/Book'
import PlaceKeywordCreate from './PlaceKeywordCreate'
import PlaceKeywordEdit from './PlaceKeywordEdit'
import PlaceKeywordShow from './PlaceKeywordShow'
import PlaceKeywordList from './PlaceKeywordList'

export default {
  list: PlaceKeywordList,
  create: PlaceKeywordCreate,
  edit: PlaceKeywordEdit,
  show: PlaceKeywordShow,
  icon: BookIcon
}
