import BookIcon from '@material-ui/icons/Book'
import HomePointChargingCreate from './HomePointChargingCreate'
import HomePointChargingEdit from './HomePointChargingEdit'
import HomePointChargingList from './HomePointChargingList'
import HomePointChargingShow from './HomePointChargingShow'

export default {
  list: HomePointChargingList,
  create: HomePointChargingCreate,
  edit: HomePointChargingEdit,
  show: HomePointChargingShow,
  icon: BookIcon
}
