import React from 'react'

import {
  CardActions,
  DateField,
  SelectField,
  Show,
  TextField,
  translate,
  ArrayField,
  Datagrid,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  EditButton,
  NumberField,
  ImageField
} from 'react-admin'

import ImageClickField from '../../custom/common/ImageClickField'
import CommentButton from './CommentButton'
import CommentListExcelExportButton from './CommentListExcelExportButton'
import EntryUserListExcelExportButton from './EntryUserListExcelExportButton'

const ShowActions = ({basePath, data, ...props}) => {
  return (
    <CardActions>
      <CommentListExcelExportButton record={data} />
      <EntryUserListExcelExportButton record={data} />
      <CommentButton record={data} type={'comment'} />
      <EditButton basePath={basePath} record={data} />
    </CardActions>
  )
}

const Expand = ({id, record, resource, ...props}) => {
  return (
    <>
      {record && (
        <ArrayField record={record} {...props} source="child" label={'답글'} addLabel={false}>
          <Datagrid>
            <TextField source="id" label={'ID'} sortable={false} />
            <ReferenceField source="userId" reference="users" linkType={'show'} basePath="/users" label={'유저'}>
              <TextField source="name" label="유저닉네임" />
            </ReferenceField>
            <TextField source="content" label={'내용'} sortable={false} />
            <DateField source="createdAt" label={'작성일'} locales="ko" showTime sortable={false} />
          </Datagrid>
        </ArrayField>
      )}
    </>
  )
}

class EventShow extends React.Component {
  render() {
    const {form, record = {}, ...props} = this.props
    const hasViewOptions = record && record.viewOptions && record.viewOptions.length > 0

    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            {/*<SelectField*/}
            {/*  source="type"*/}
            {/*  choices={[*/}
            {/*    {id: 'notice', name: '공지사항'},*/}
            {/*    {id: 'event', name: '이벤트'}*/}
            {/*  ]}*/}
            {/*/>*/}
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="content" />
            <NumberField source="rolling_order" />
            <ImageClickField label="대표 이미지" addLabel={true} source="titleImagePath" src="image" />
            <ImageClickField label="상세 이미지" addLabel={true} source="images" src="image" />
            <ImageClickField label="하단 이미지" addLabel={true} source="bottomImages" src="image" />

            <SelectField
              source="form"
              label="이벤트 형태"
              choices={[
                {id: 'general', name: '단순 게시형'},
                {id: 'entry', name: '단순 응모형'},
                {id: 'pick', name: '단일 선택 응모형'},
                {id: 'attendance', name: '출석 이벤트형'},
                {id: 'ctaButton', name: '링크 이동형'}
              ]}
            />
            {form === 'pick' ? (
              <>
                <ArrayField source="viewOptions" label="선택지 보기 옵션">
                  <Datagrid>
                    <TextField source="title" label="선택지 보기 아이템" />
                    <SelectField
                      source="type"
                      label="버튼 형태"
                      choices={[
                        {id: 'selectionButton', name: '선택 버튼'},
                        {id: 'entryButton', name: '응모 버튼'}
                      ]}
                    />
                    <ImageClickField label="beforeSelection" addLabel={true} source="beforeSelection" src="image" />
                    <ImageClickField label="afterSelection" addLabel={true} source="afterSelection" src="image" />
                    <ImageClickField label="expiredSelection" addLabel={true} source="expiredSelection" src="image" />
                  </Datagrid>
                </ArrayField>
              </>
            ) : null}

            {form === 'attendance' ? (
              <>
                <DateField source="viewOptions[0]?.viewAttendanceDate" label="출석이벤트 진행 월" />
              </>
            ) : null}

            <ArrayField source="viewOptions" label="버튼 내용">
              <Datagrid>
                <TextField source="url" label="URL" />
                <ImageClickField addLabel={true} source="urlImage" label="URL 이미지" src="image" />
              </Datagrid>
            </ArrayField>

            <DateField source="createdAt" />
            <DateField source="startDate" />
            <DateField source="endDate" />
            <DateField source="periodStart" locales="ko" showTime sortable={true} />
            <DateField source="periodEnd" locales="ko" showTime sortable={true} />
          </Tab>

          <Tab label={'댓글 정보'}>
            <ArrayField source="comments" addLabel={false}>
              <Datagrid aria-expanded="false" expand={<Expand />}>
                <TextField source="id" label="댓글ID" sortable={false} />
                <ReferenceField source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" label="유저닉네임" />
                </ReferenceField>
                <TextField source="content" label={'내용'} sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'참여 내역'}>
            <ArrayField source="participation" addLabel={false}>
              <Datagrid aria-expanded="false" expand={<Expand />}>
                <TextField source="id" label="유저ID" sortable={false} />
                <ReferenceField source="userId" reference="users" linkType={'show'}>
                  <TextField source="name" label="유저닉네임" />
                </ReferenceField>
                <TextField source="content" label={'내용'} sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(EventShow)
