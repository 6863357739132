import React from 'react'
import {DateTimeInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <ReferenceInput label="카테고리" source="community_category" reference="communityCategory" alwaysOn perPage={200}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="id" disabled />
      <DateTimeInput source="createdAt" locales="ko" />
    </SimpleForm>
  </Edit>
)
