import React from 'react'
import {Create, SelectInput, SimpleForm, TextInput} from 'react-admin'
import {password, required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Create {...props} actions={<CreateActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <TextInput source="name" validate={required} />
      <TextInput source="password" type="password" validate={[required, password]} />
      {/*<TextInput source="adminName" validate={required}/>*/}
      <SelectInput
        source="auth"
        choices={[
          {id: 'admin', name: '관리자'},
          {id: 'manager', name: '매니저'}
        ]}
      />
    </SimpleForm>
  </Create>
)
