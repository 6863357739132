import BookIcon from '@material-ui/icons/Book'
import JointPurchaseCreate from './JointPurchaseCreate'
import JointPurchaseEdit from './JointPurchaseEdit'
import JointPurchaseShow from './JointPurchaseShow'
import JointPurchaseList from './JointPurchaseList'

export default {
  list: JointPurchaseList,
  create: JointPurchaseCreate,
  edit: JointPurchaseEdit,
  show: JointPurchaseShow,
  icon: BookIcon
}
