import React from 'react'
import {BooleanField, DateField, Show, SimpleShowLayout, TextField} from 'react-admin'

export const MessagesShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="senderId" />
        <TextField source="senderName" />
        <TextField source="text" />
        <BooleanField source="read" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  )
}
