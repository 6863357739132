import React from 'react'
import {Edit, SimpleForm, NumberInput, BooleanInput, TextInput} from 'react-admin'

import EditActions from '../../custom/common/EditActions'
import {required} from '../../validators'
import CreateToolbar from '../../custom/common/CreateToolbar'

export default (props) => (
  <Edit {...props} actions={<EditActions />}>
    <SimpleForm toolbar={<CreateToolbar />}>
      <NumberInput source="id" defaultValue={1000} validate={required} />
      <BooleanInput source="display" defaultValue={false} validate={[required]} />
    </SimpleForm>
  </Edit>
)
