import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SelectField,
  SelectInput,
  TextField
} from 'react-admin'
import PhoneField from '../../custom/common/PhoneField'
import choices from '../orders20/choices'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => <CardActions></CardActions>
const ListFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      choices={[
        {id: 'delivered', name: '배송 완료'},
        {id: 'cancelled', name: '주문 취소'}
      ]}
      alwaysOn
    />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List
      {...props}
      actions={<Actions />}
      sort={{field: 'createdAt', order: 'DESC'}}
      perPage={25}
      filters={<ListFilter />}
      bulkActions={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="buyerName" sortable={false} />
        <PhoneField source="buyerPhone" sortable={false} />
        <NumberField source="amount" sortable={false} />
        <NumberField source="point" sortable={false} />
        <SelectField source="method" choices={choices.methods} sortable={false} />
        <SelectField source="status" choices={choices.status} sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={true} />
      </Datagrid>
    </List>
  )
})
