import React from 'react'

import {Show, SimpleShowLayout, TextField, translate, DateField, NumberField} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from '../../custom/common/ImageClickField'
import {BooleanField} from 'ra-ui-materialui'

class ServiceShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />

          <ImageClickField label="이미지" addLabel={true} source="imagePath" />

          <BooleanField source="isActive" />
          <NumberField source="order" />
          <DateField source="createdAt" locales="ko" showTime sortable={false} />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(ServiceShow)
