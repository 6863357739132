import React, {useState} from 'react'
import {
  TextField,
  BooleanInput,
  Create,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  LongTextInput,
  maxLength,
  SelectInput,
  SimpleForm,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  NumberInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

const fullPageHrStyle = {
  width: '100%',
  borderTop: '1px solid #ccc', // 변경 가능한 가로선 스타일
  margin: '16px 0'
}

export default (props) => {
  const [imageData, setImageData] = useState([])
  const [bottomImageData, setBottomImageData] = useState([])
  // const [urlImageData, setUrlImageData] = useState([])
  const [multiCtaButtonImageData, setMultiCtaButtonImageData] = useState([])
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput
          source="type"
          choices={[{id: 'event', name: '이벤트'}]}
          validate={required}
          defaultValue={'event'}
        />
        <LongTextInput source="title" validate={[required, maxLength(150)]} />
        <LongTextInput source="content" validate={[required]} />
        <NumberInput source="rolling_order" validate={[required]} />
        <TextField label="노출 순서를 원하시면 해당 기간내에 다른 이벤트랑 순서가 겹치지않는지 확인하시고 입력해주세요." />
        <LongTextInput source="pushTitle" defaultValue={'-'} validate={[required, maxLength(150)]} />
        <ImageInput source="titleImagePath" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({formData, ...rest}) => {
            formData.images = imageData.length > 0 ? imageData : []
            formData.bottomImages = bottomImageData.length > 0 ? bottomImageData : []
            formData.multiCtaButtonImageData = multiCtaButtonImageData.length > 0 ? multiCtaButtonImageData : []

            return (
              <>
                <OrderImageInput
                  label="상세 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setImageData}
                  source="images"
                  accept="image/*"
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>
                <OrderImageInput
                  label="하단 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setBottomImageData}
                  source="bottomImages"
                  accept="image/*"
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>
                <br />
                <hr style={fullPageHrStyle} />
                <br />

                <BooleanInput
                  source="isCtaButtonAvailable"
                  validate={[required]}
                  label="CTA 버튼을 활성화 하시겠습니까?"
                  defaultValue={false}
                ></BooleanInput>

                {formData && formData.type === 'event' && formData.isCtaButtonAvailable === true ? (
                  <ArrayInput source="viewOptions" label="멀티 버튼 적용" validate={[required]}>
                    <SimpleFormIterator>
                      <TextInput source="url" label="링크 URL" validate={[required]} />
                      <OrderImageInput
                        label="URL 이미지"
                        placeholder={<p>이미지를 넣어주세요</p>}
                        multiple={false}
                        setState={setMultiCtaButtonImageData}
                        source="urlImage"
                        accept="image/*"
                        validate={[required]}
                      >
                        <ImageField source="image" title="title" validate={required} />
                      </OrderImageInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}
                {/*{formData && formData.type === 'event' && formData.isCtaButtonAvailable === true ? (*/}
                {/*  <TextInput source="url" label="링크 URL" />*/}
                {/*) : (*/}
                {/*  <div></div>*/}
                {/*)}*/}
                {/*{formData && formData.type === 'event' && formData.isCtaButtonAvailable === true ? (*/}
                {/*  <OrderImageInput*/}
                {/*    label="URL 이미지"*/}
                {/*    placeholder={<p>이미지를 넣어주세요</p>}*/}
                {/*    multiple*/}
                {/*    setState={setUrlImageData}*/}
                {/*    source="urlImage"*/}
                {/*    accept="image/*"*/}
                {/*  >*/}
                {/*    <ImageField source="image" title="title" />*/}
                {/*  </OrderImageInput>*/}
                {/*) : (*/}
                {/*  <div></div>*/}
                {/*)}*/}
              </>
            )
          }}
        </FormDataConsumer>
        <br />
        <br />
        <hr style={fullPageHrStyle} />
        <br />
        <BooleanInput source="push" validate={[required]} defaultValue={false} />
        <TextField label="예약할 푸시 시간은 현재(푸시 업로드 시점)보다 2시간 이후이어야 등록되며, 2시간 이내이면 즉시 푸시됩니다. 또한, 각 푸시 발송 시간은 2시간 이상 차이가 나야 정상 등록됩니다." />
        <DateTimeInput
          label="푸시 예약 발송 일시"
          source="pushDateTime"
          defaultValue={new Date('2021-05-01 12:00:00')}
        />
        <DateInput label="시작 날짜" source="startDate" />
        <DateInput label="종료 날짜" source="endDate" />
        <DateTimeInput source="periodStart" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="periodEnd" locales="ko" validate={[required]} defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  )
}
