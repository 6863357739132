import React from 'react'

import {
  ArrayField,
  CardActions,
  CardContentInner,
  Datagrid,
  ImageField,
  ListButton,
  NumberField,
  ReferenceField,
  SelectField,
  ShowController,
  SimpleShowLayout,
  TextField,
  TitleForRecord,
  translate
} from 'react-admin'
import {withStyles} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import OrderReceiver from '../orders20/OrderReceiver'
import OrderStatus from '../orders20/OrderStatus'
import OrderUser from '../orders20/OrderUser'
import OrderInfoButton from '../orders20/OrderInfoButton'
import OrderStatusButton from '../orders20/OrderStatusButton'
import {history} from '../../App'
import choices from '../orders20/choices'

const ShowActions = ({basePath, ...props}) => {
  const permissions = localStorage.getItem('auth')
  return (
    <CardActions>
      {permissions !== 'manager' && props.data && props.data.statusLog && (
        <OrderInfoButton basePath={basePath} record={props.data} />
      )}
      {permissions !== 'manager' && props.data && props.data.statusLog && (
        <OrderStatusButton basePath={basePath} record={props.data} />
      )}
      <ListButton basePath={basePath} />
    </CardActions>
  )
}
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const styles = {
  root: {display: 'flex', alignItems: 'flex-start'},
  form: {flexGrow: 2, marginRight: '2em'},
  container: {width: '35em'}
}

class OrderShow extends React.Component {
  render() {
    const {classes, translate, ...props} = this.props
    return (
      <ShowController {...props}>
        {({isLoading, defaultTitle, ...controllerProps}) => {
          const record = controllerProps.record
          return controllerProps.record ? (
            <div className={classes.root}>
              <Card className={classes.form}>
                <TitleForRecord defaultTitle={defaultTitle} record={record} />
                <CardContentInner>
                  <ShowActions basePath={controllerProps.basePath} data={record} />
                </CardContentInner>
                <SimpleShowLayout {...controllerProps}>
                  <TextField source="id" />
                  <ReferenceField source="userId" reference="users" allowEmpty linkType={'show'}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ArrayField source="products" label={'상품'}>
                    <Datagrid rowClick={(record, resource, row) => history.push(`/products/${row.id}/show`)}>
                      <TextField label={translate('resources.products.fields.id')} source="id" sortable={false} />
                      <ThumbnailImageField
                        label={translate('resources.products.fields.titleImagePath')}
                        source="titleImagePath"
                        sortable={false}
                      />
                      <TextField label={translate('resources.products.fields.name')} source="name" sortable={false} />
                      <TextField label={'옵션명'} source="optionName" sortable={false} />
                      <NumberField label={'제품가'} source="price" sortable={false} />
                      <NumberField label={'옵션가'} source="optionPrice" sortable={false} />
                      <NumberField
                        label={translate('resources.orders20.fields.quantity')}
                        source="quantity"
                        sortable={false}
                      />
                      <NumberField label={'합계'} source="totalPrice" sortable={false} />
                      {/*<TextField source="optionName" sortable={false}/>*/}
                    </Datagrid>
                  </ArrayField>
                  <SelectField source="method" choices={choices.methods} />
                  <NumberField source="deliveryFee" />
                  <NumberField source="point" />
                  <NumberField source="amount" />
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.name')} source="vbank.bank" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.holder')} source="vbank.P_VACT_NAME" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.number')} source="vbank.P_VACT_NUM" />
                  )}
                  {record.vbank && (
                    <TextField label={translate('resources.orders20.fields.vbank.expire')} source="vbank.expire" />
                  )}
                  {record.vbankRefund && <TextField source="vbankRefund.bank" />}
                  {record.vbankRefund && <TextField source="vbankRefund.holder" />}
                  {record.vbankRefund && <TextField source="vbankRefund.account" />}
                  <TextField source="memo" />
                </SimpleShowLayout>
              </Card>
              <div style={{display: 'block'}}>
                <OrderStatus record={record} />
                <OrderUser record={record} />
                <OrderReceiver record={record} />
              </div>
            </div>
          ) : (
            ''
          )
        }}
      </ShowController>
    )
  }
}

export default withStyles(styles)(translate(OrderShow))
