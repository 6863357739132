import React from 'react'

import {
  BooleanField,
  ChipField,
  maxLength,
  minLength,
  NumberField,
  ReferenceArrayField,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  translate
} from 'react-admin'
import ShowActions from '../../custom/common/ShowActions'
import ImageClickField from '../../custom/common/ImageClickField'
import RichTextField from 'ra-ui-materialui/lib/field/RichTextField'

class PlaceShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <BooleanField source="recommendPick" defaultValue={false} />
          <SelectField
            source="type"
            choices={[
              {id: 'dfangMembers', name: '디팡 멤버스'},
              {id: 'dfangStore', name: '디팡 스토어'},
              {id: 'normal', name: '일반'}
            ]}
          />

          <TextField source="name" />
          <TextField source="dfangName" validate={[minLength(1), maxLength(50)]} />
          <TextField source="tel" />
          <TextField source="address" />
          <TextField source="addressDetail" />
          <RichTextField style={{whiteSpace: 'pre-wrap'}} source="description" />

          <ImageClickField label="대표 이미지" addLabel={true} source="titleImagePath" />

          <ImageClickField label="상세 이미지" addLabel={true} source="images" src="src" />

          <TextField source="weekdayBusinessHourStart" />
          <TextField source="weekdayBusinessHourEnd" />
          <TextField source="weekendBusinessHourStart" />
          <TextField source="weekendBusinessHourEnd" />

          <TextField source="holiday" label="휴무 요일" />

          <TextField source="homepage" />
          <TextField source="facebook" />
          <TextField source="instagram" />
          <TextField source="kakaotalk" />
          <TextField source="blog" />

          <ReferenceArrayField label="견종" reference="placeBreeds" source="breedIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField label="편의시설 및 제공서비스" reference="facilities" source="facilityIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField label="업종" reference="services" source="serviceIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField label="장소 키워드" reference="placeKeywords" source="keywordIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <BooleanField source="dfangDiscount" defaultValue={false} />
          <BooleanField source="useCoupon" defaultValue={false} />
          <BooleanField source="usePoint" defaultValue={false} />
          <NumberField source="coordinate.x" options={{maximumFractionDigits: 10}} />
          <NumberField source="coordinate.y" options={{maximumFractionDigits: 10}} />
          <NumberField source="order" />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(PlaceShow)
