import BookIcon from '@material-ui/icons/Book'
import ServiceCreate from './ServiceCreate'
import ServiceEdit from './ServiceEdit'
import ServiceShow from './ServiceShow'
import ServiceList from './ServiceList'

export default {
  list: ServiceList,
  create: ServiceCreate,
  edit: ServiceEdit,
  show: ServiceShow,
  icon: BookIcon
}
