import React from 'react'

import {
  ArrayField,
  BooleanField,
  CardActions,
  Datagrid,
  DateField,
  EmailField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import AccountField from '../users/AccountField'
import PhoneField from '../../custom/common/PhoneField'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'

const ShowActions = ({history, ...props}) => {
  return (
    <CardActions>
      <CustomDeleteButton {...props} record={props.data} submitOnEnter={false} variant="dense" />
    </CardActions>
  )
}

class DeletedUserShow extends React.Component {
  render() {
    return (
      <Show {...this.props} actions={<ShowActions {...this.props} />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" />
            <TextField source="name" />
            <AccountField />
            <PhoneField source="phone" />
            <EmailField source="accounts.email" />
            <NumberField source="point" />
            <NumberField source="expirePoint" />
            <BooleanField source="agreeMarketing" />
            <ReferenceField source="referrerId" reference="users" allowEmpty linkType={'show'}>
              <TextField source="name" />
            </ReferenceField>
            <DateField source="registeredAt" locales="ko" showTime />
            <DateField source="createdAt" locales="ko" showTime />
          </Tab>
          <Tab label={'산책 내역'}>
            <ArrayField source="walks" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <NumberField label={'거리(m)'} source="distance" sortable={false} />
                <NumberField label={'시간(분)'} source="duration" sortable={false} />
                <DateField label={'산책일'} source="walkedAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label={'반려견 정보'}>
            <ArrayField source="dogs" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="name" sortable={false} />
                <TextField source="breedName" label={'견종'} sortable={false} />
                <TextField source="gender" label={'성'} sortable={false} />
                <DateField source="birth" label={'생일'} locales="ko" showTime sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(DeletedUserShow)
