import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {
  List,
  Filter,
  CardActions,
  Datagrid,
  DateInput,
  SearchInput,
  DateField,
  TextField,
  EmailField,
  NumberField,
  BooleanField,
  ReferenceField
} from 'react-admin'
import AccountField from '../users/AccountField'
import {dataProvider} from '../../App'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => <CardActions></CardActions>
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={'검색'} source="search" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
  </Filter>
)

const CustomTextField = (props) => {
  const [title, setTitle] = useState(null)
  const [redeemId, setRedeemId] = useState(null)
  useEffect(() => {
    props.record.redeemCodeId &&
      dataProvider('GET_CODE', 'redeems', {id: props.record.redeemCodeId}).then((data) => {
        setTitle(data.data.title)
        setRedeemId(data.data.redeemId)
      })
  }, [props.record.redeemCodeId])
  return (
    <>
      {props.record.redeemCodeId && (
        <span
          style={{color: '#3f51b5', cursor: 'pointer'}}
          source="title"
          onClick={(e) => {
            props.history.push(`/redeems/${redeemId}/show`)
            e.stopPropagation()
          }}
        >
          {title}
        </span>
      )}
    </>
  )
}

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true} />
        <TextField source="name" sortable={true} />
        <AccountField sortable={false} />
        <TextField source="phone" />
        <NumberField source="point" />
        <NumberField source="expirePoint" />
        <EmailField source="accounts.email" />
        <BooleanField source="agreeMarketing" />
        <ReferenceField source="referrerId" reference="users" linkType="show" sortable={true} allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <CustomTextField source="redeemCodeId" sortable={false} {...props} />
        <NumberField source="walkCount" sortable={false} />
        <NumberField source="postCount" sortable={false} />
        <NumberField source="purchaseCount" sortable={false} />
        <NumberField source="purchasePrice" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={true} />
        <DateField source="registeredAt" locales="ko" showTime />
      </Datagrid>
    </List>
  )
})
