import React, {useEffect, useState} from 'react'
import ChartJS from 'chart.js'

const PointChart = ({canvasId, label, data, stack}) => {
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (label) {
      const options = {
        maintainAspectRatio: false,
        spanGaps: false,
        elements: {
          line: {
            tension: 0.000001
          }
        },
        plugins: {
          filler: {
            propagate: false
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 0
            }
          },
          yAxes: [
            {
              ticks: {
                autoSkip: false,
                scaleBeginAtZero: true
              }
            }
          ]
        }
      }

      const chartjs = new ChartJS('chart-' + canvasId, {
        type: 'line',
        data: {
          labels: Array.from({length: 31}, (_, i) => `D-${i}`),
          datasets: [
            {
              backgroundColor: '#FFF',
              borderColor: stack ? '#293793' : '#FF6384',
              data: [],
              label: stack ? '누적' : '당일',
              fill: false
            }
          ]
        },
        options: ChartJS.helpers.merge(options, {
          title: {
            text: `${label}(${stack ? '누적' : '당일'})`,
            display: true
          }
        })
      })
      setChart(chartjs)
    }
  }, [canvasId, label, stack])
  useEffect(() => {
    if (chart) {
      const today = () => {
        let todayData = []
        if (data) {
          todayData = data.data.map((day, index) => {
            return {x: index, y: day.save + day.use}
          })
        }
        return todayData
      }

      chart.config.data.datasets[0].data = today()
      chart.update()
    }
  }, [data, chart])

  return (
    <>
      <canvas key={`chart-${canvasId}`} id={`chart-${canvasId}`} />
    </>
  )
}
export default PointChart
