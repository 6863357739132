import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {ImageParse} from './ImageMethod'
import Jimp from 'jimp'

export async function request(type, params) {
  let url = '/admin/knowledge'

  let options = {}
  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data

      let data = {...params.data}

      if (options.data.thumbnailPath.image) {
        if (options.data.thumbnailPath.image.substring(0, 5) === 'https') {
          options.data.thumbnailPath.image = ImageParse(options.data.thumbnailPath.image, 'knowledge')
        }
      }
      options.data = await uploadToS3(data, 'thumbnailPath', 'knowledge', 'thumbnailPath')
      options = await cutImages(options, data)
      data = options.data
      options.data = await uploadToS3(data, 'images', 'knowledge', 'images')
      //위 부분에서 순서가 바뀐다 나중에 처리해주기
      const src = options.data.thumbnailPath.image
      options.data.thumbnailPath = src

      options.data.images = options.data.images.map((value) => value.image)

      if (options.data.thumbnailPath.substring(0, 5) === 'https') {
        options.data.thumbnailPath = ImageParse(options.data.thumbnailPath, 'knowledge')
      }

      const parse = options.data.images.map((v) => {
        if (v.substring(0, 5) === 'https') {
          v = ImageParse(v, 'knowledge')
        }
        return v
      })
      options.data.images = parse
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      data.thumbnailPath = convertImagePath(data.thumbnailPath, 'thumbnail')
      const dataImage = data.images.map((value) => {
        return {image: convertImagePath(value, 'large')}
      })
      data.images = dataImage
      if (typeof data.thumbnailPath === 'string') {
        const image = data.thumbnailPath
        data.thumbnailPath = {}
        data.thumbnailPath.image = image
      }
      ret = {data}
      break
    case GET_LIST:
      data.data.forEach((row) => (row.thumbnailPath = convertImagePath(row.thumbnailPath, 'large')))
      ret = data
      break
    case DELETE:
      ret = {data: params}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}

export const cutImages = async (options, data) => {
  const cutImage = await Promise.all(
    options.data.images.map(async (image) => {
      let imageArray = []
      if (image.rawFile) {
        const rawFile = image
        let longImageResize = await Jimp.read(rawFile.rawFile.preview)
        longImageResize.resize(750, Jimp.AUTO)
        const count = Math.ceil(longImageResize.bitmap.height / 750)
        for (let i = 0; i < count; i++) {
          const cropImage = longImageResize.clone()
          if (i === count - 1 && count !== 1) cropImage.crop(0, i * 750, 750, longImageResize.bitmap.height % 750)
          else if (count === 1) cropImage.crop(0, i * 750, 750, 750)
          else cropImage.crop(0, i * 750, 750, 750)
          const buffer = await cropImage.getBufferAsync(rawFile.rawFile.type)
          await cropImage.getBufferAsync(rawFile.rawFile.type)
          const newRawFile = {rawFile: {preview: buffer, type: image.rawFile.type}}
          imageArray.push(newRawFile)
        }
      } else {
        imageArray = image
      }
      return imageArray
    })
  )
  options.data.images = cutImage
  options.data.images = options.data.images.flat()
  return options
}
