import React from 'react'
import 'moment/locale/ko'
import {createBrowserHistory} from 'history'
import {Admin, Resource} from 'react-admin'
import {reducer as tree} from 'ra-tree-ui-materialui'
import authProvider from './providers/authProvider'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'

import Auth from './custom/auth'
import Layout from './custom/Layout'
import {
  admins,
  home,
  designSource,
  gaReports,
  banners,
  breeds,
  categories,
  communityCategory,
  categorySympathy,
  comments,
  deletedOrders,
  deletedUsers,
  facilities,
  knowledge,
  seedCampaign,
  seedCampaignInquiry,
  seedCampaignInquiryCategory,
  seedCampaignBestReview,
  seedCampaignApply,
  seedCampaignAdvertiser,
  events,
  eventsComments,
  exhibition,
  notices,
  noticeComments,
  orders20,
  placeBreeds,
  placeKeywords,
  places,
  products,
  redeems,
  reviews,
  sellers,
  services,
  talks,
  communityPopular,
  users,
  points,
  messages,
  balanceAccount, //정산관리(신)
  productQAs, //문의 관리(신)
  productReviews, //(reviews),  //리뷰 관리(신규)
  productReviewReports, //상품(주문) 리뷰 관리 신고
  ugcReports, //상품문의, 쪽지, 댓글 대상
  sellerNotices,
  advertising,
  popups,
  offerwall,
  donation,
  donationDestination,
  donationGoods,
  pointExchange,
  jointPurchase,
  homePointCharging
} from './components'

import {DashBoard} from './custom/dashboard/DashBoard'

export const history = createBrowserHistory()
export const dataProvider = _dataProvider(process.env.REACT_APP_URL)

const App = () => {
  return (
    <Admin
      locale="en"
      title={process.env.NODE_ENV === 'production' ? '펫피 어드민' : '펫피 어드민 - DEV'}
      appLayout={Layout}
      loginPage={Auth}
      dashboard={DashBoard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{tree}}
      history={history}
    >
      {(permissions) => {
        const resources = []
        if (permissions === 'master') {
          resources.push(
            //시스템관리
            <Resource name="admins" {...admins} />,
            <Resource name="home" {...home} />,
            <Resource name="homePointCharging" {...homePointCharging} />,
            <Resource name="designSource" {...designSource} />,
            <Resource name="gaReports" {...gaReports} />
          )
        }
        resources.push(
          <Resource name="dashboard" />,
          <Resource name="files" />,
          //사용자관리
          <Resource name="users" {...users} />,
          <Resource name="deletedUsers" {...deletedUsers} />,
          <Resource name="messages" {...messages} />,
          <Resource name="points" {...points} />,
          <Resource name="breeds" {...breeds} />,
          //추천인 코드 관리
          <Resource name="redeems" {...redeems} />,
          //커뮤니티 관리
          <Resource name="notices" {...notices} />,
          <Resource name="noticeComments" {...noticeComments} />,
          <Resource name="communityPopular" {...communityPopular} />,
          <Resource name="talks" {...talks} />,
          <Resource name="comments" {...comments} />,
          <Resource name="communityCategory" {...communityCategory} />,
          //체험단 관리
          <Resource name="seedCampaign" {...seedCampaign} />,
          <Resource name="seedCampaignInquiry" {...seedCampaignInquiry} />,
          <Resource name="seedCampaignInquiryCategory" {...seedCampaignInquiryCategory} />,
          <Resource name="seedCampaignBestReview" {...seedCampaignBestReview} />,
          <Resource name="seedCampaignApply" {...seedCampaignApply} />,
          <Resource name="seedCampaignAdvertiser" {...seedCampaignAdvertiser} />,
          //이벤트 관리
          <Resource name="events" {...events} />,
          <Resource name="eventsComments" {...eventsComments} />,
          //공동구매 관리
          <Resource name="jointPurchase" {...jointPurchase} />,
          //신고 관리
          <Resource name="ugcReports" {...ugcReports} />,
          //광고 관리
          <Resource name="advertising" {...advertising} />,
          <Resource name="popups" {...popups} />,
          <Resource name="offerwall" {...offerwall} />,
          //포인트 교환소 관리
          <Resource name="pointExchange" {...pointExchange} />,
          //쇼핑 관리
          <Resource name="products" {...products} />,
          <Resource name="categories" {...categories} />,
          <Resource name="productQAs" {...productQAs} />,
          <Resource name="productReviews" {...productReviews} />,
          <Resource name="productReviewReports" {...productReviewReports} />,
          <Resource name="exhibition" {...exhibition} />,
          <Resource name="banners" {...banners} />,
          <Resource name="sellers" {...sellers} />,
          <Resource name="sellerNotices" {...sellerNotices} />,
          //주문/정산 관리
          <Resource name="orders20" {...orders20} />,
          <Resource name="balanceAccount" {...balanceAccount} />,
          <Resource name="deletedOrders" {...deletedOrders} />,
          //장소 관리
          <Resource name="places" {...places} />,
          <Resource name="facilities" {...facilities} />,
          <Resource name="services" {...services} />,
          <Resource name="placeBreeds" {...placeBreeds} />,
          <Resource name="placeKeywords" {...placeKeywords} />,
          //컨텐츠 관리
          <Resource name="knowledge" {...knowledge} />,
          <Resource name="categorySympathy" {...categorySympathy} />,
          <Resource name="reviews" {...reviews} />,
          //기부 관리
          <Resource name="donation" {...donation} />,
          <Resource name="donationDestination" {...donationDestination} />,
          <Resource name="donationGoods" {...donationGoods} />
        )
        return resources
      }}
    </Admin>
  )
}

export default App
