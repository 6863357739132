import BookIcon from '@material-ui/icons/Book'
import AdminCreate from './AdminCreate'
import AdminEdit from './AdminEdit'
import AdminShow from './AdminShow'
import AdminList from './AdminList'

export default {
  list: AdminList,
  create: AdminCreate,
  edit: AdminEdit,
  show: AdminShow,
  icon: BookIcon
}
