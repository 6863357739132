import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  SelectInput,
  TextField
} from 'react-admin'
import ListExcelExportButton from './ListExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath, ...props}) => (
  <CardActions>
    <ListExcelExportButton {...props} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="유저" source="user" alwaysOn />
    <SearchInput label="제목" source="search" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
    <SelectInput
      label="게시글 유형"
      source="category"
      choices={[
        {id: 'board', name: '게시판'},
        {id: 'walk', name: '산책'},
        {id: 'gallery', name: '갤러리'},
        {id: 'counseling', name: '상담'}
      ]}
      alwaysOn
    />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions {...props} />} perPage={25} filters={<ListFilter />} bulkActions={true}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ReferenceField source="userId" reference="users" allowEmpty linkType={'show'}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="talkId" reference="talks" allowEmpty linkType={'show'}>
          <TextField source="title" />
        </ReferenceField>
        <TextField source="content" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
