import React, {useState} from 'react'
import {
  BooleanInput,
  DateInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ImageField,
  maxLength,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

export default (props) => {
  const [imageData, setImageData] = useState([])
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <SelectInput
          source="type"
          choices={[
            {id: 'notice', name: '공지사항'},
            {id: 'event', name: '이벤트'}
          ]}
          validate={required}
        />
        <TextInput source="title" multiline validate={[required, maxLength(50)]} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) {
              formData.images = imageData
            }
            return (
              <OrderImageInput
                label="이미지"
                placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                multiple
                setState={setImageData}
                source="images"
                accept="image/*"
              >
                <ImageField source="image" title="title" />
              </OrderImageInput>
            )
          }}
        </FormDataConsumer>
        <TextInput source="content" multiline validate={[required]} />
        <BooleanInput source="push" validate={[required]} defaultValue={false} />
        <DateInput label="시작 날짜" source="startDate" />
        <DateInput label="종료 날짜" source="endDate" />
        <DateTimeInput source="createdAt" validate={required} />
      </SimpleForm>
    </Edit>
  )
}
