import React, {useState} from 'react'
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  maxLength,
  minLength,
  minValue,
  maxValue,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {required, tel, uri} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import TimePickers from '../../custom/common/TimePickers'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'
import Button from '@material-ui/core/Button'
import GoogleMap from '../../custom/common/GoogleMap'

export default (props) => {
  const [geom, setGeom] = useState(null)
  const [data, setData] = useState([])
  const handleButton = async () => {
    const currentAddr = document.getElementById('address').value
    if (currentAddr) {
      const {lat, lng} = await GoogleMap(currentAddr)
      setGeom({lat: lat, lng: lng})
    }
  }
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <BooleanInput source="recommendPick" defaultValue={false} />
        <SelectInput
          source="type"
          choices={[
            {id: 'dfangMembers', name: '디팡 멤버스'},
            {id: 'dfangStore', name: '디팡 스토어'},
            {id: 'normal', name: '일반'}
          ]}
          validate={required}
        />

        <TextInput source="name" validate={[required, maxLength(50)]} />
        <TextInput source="dfangName" validate={[minLength(1), maxLength(50)]} />
        <TextInput source="tel" validate={[required, tel]} />
        <TextInput source="address" validate={[required, maxLength(100)]} />
        <TextInput source="addressDetail" validate={[maxLength(100)]} defaultValue={''} />
        <TextInput source="description" multiline defaultValue={''} />

        <ImageInput source="titleImagePath" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (data.length > 0) {
              formData.images = data
            }
            if (geom) {
              if (!formData.coordinate) formData.coordinate = {}
              if (formData.coordinate.x !== geom.lng) {
                formData.coordinate.x = Number(geom.lng)
                formData.coordinate.y = Number(geom.lat)
              }
            }
            return (
              <OrderImageInput
                label="상세 이미지"
                placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                multiple
                setState={setData}
                source="images"
                accept="image/*"
                validate={required}
              >
                <ImageField source="image" title="title" />
              </OrderImageInput>
            )
          }}
        </FormDataConsumer>

        <TimePickers source="weekdayBusinessHourStart" label="주중 오픈 시간" />
        <TimePickers source="weekdayBusinessHourEnd" label="주중 마감 시간" />
        <TimePickers source="weekendBusinessHourStart" label="주말 오픈 시간" />
        <TimePickers source="weekendBusinessHourEnd" label="주말 마감 시간" />

        <TextInput source="holiday" label="휴무 요일" defaultValue={''} />

        <TextInput source="homepage" validate={uri} />
        <TextInput source="facebook" validate={uri} />
        <TextInput source="instagram" validate={uri} />
        <TextInput source="kakaotalk" validate={uri} />
        <TextInput source="blog" />

        <ReferenceArrayInput source="breedIds" reference="placeBreeds" validate={required} defaultValue={[]}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput source="facilityIds" reference="facilities" defaultValue={[]}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput source="serviceIds" reference="services" defaultValue={[]}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput source="keywordIds" reference="placeKeywords" defaultValue={[]}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <BooleanInput source="dfangDiscount" defaultValue={false} />
        <BooleanInput source="useCoupon" defaultValue={false} />
        <BooleanInput source="usePoint" defaultValue={false} />
        <NumberInput source="order" defaultValue={1000} validate={required} />
        <NumberInput source="coordinate.x" validate={[minValue(-180), maxValue(180)]} />
        <NumberInput source="coordinate.y" validate={[minValue(-90), maxValue(90)]} />
        <Button onClick={handleButton} variant="contained" color="grey" style={{marginTop: '15px'}}>
          위/경도 가져오기
        </Button>
      </SimpleForm>
    </Create>
  )
}
