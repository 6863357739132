import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SearchInput,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = ({basePath}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={'이름'} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <BooleanField source="recommended" sortable={false} />
        <NumberField source="order" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
