import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin'
import '../../css/index.css'

import PointBundleButton from './PointBundleButton'
import PointListExcelExportButton from './PointListExcelExportButton'
import PointListSearchExcelExportButton from './PointListSearchExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const Actions = (props) => (
  <CardActions>
    <PointListSearchExcelExportButton {...props} />
    <PointBundleButton {...props} />
    <CreateButton {...props} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    {/*<TextInput label={'유저 ID'} source="userId" alwaysOn validate={[regex(/^[0-9]*$/)]} />*/}
    <TextInput label={'유저 ID'} source="userId" alwaysOn />
    <SelectInput
      label={'소멸 분류'}
      source="filter"
      choices={[
        {id: 'expired', name: '소멸'},
        {id: 'willExpire', name: '소멸 예정'}
      ]}
      alwaysOn
    />
    <DateInput label="생성일 From" source="createdAtStart" alwaysOn />
    <DateInput label="생성일 To" source="createdAtEnd" alwaysOn />
    {/*<ReferenceInput*/}
    {/*  label="포인트내역종류"*/}
    {/*  source="description"*/}
    {/*  reference="points"*/}
    {/*  filter={{filter: 'description'}}*/}
    {/*  alwaysOn*/}
    {/*  perPage={300}*/}
    {/*>*/}
    {/*  <SelectInput optionText="description" />*/}
    {/*</ReferenceInput>*/}
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={true} />
        <ReferenceField label="사용자 이름" source="userId" reference="users" linkType="show" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <SelectField
          source="type"
          sortable={false}
          choices={[
            {id: 'custom', name: '지정'},
            {id: 'cancelOrder', name: '주문 취소'},
            {id: 'walk', name: '산책'},
            {id: 'purchase', name: '구매'}
          ]}
        />
        <NumberField source="point" sortable={false} />
        <NumberField source="remainPoint" sortable={false} />
        <TextField source="description" sortable={false} />
        <DateField source="expireAt" locales="ko" showTime sortable={true} />
        <DateField source="createdAt" locales="ko" showTime sortable={true} />
      </Datagrid>
    </List>
  )
})
