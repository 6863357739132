import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  ImageField,
  List,
  NumberField,
  SearchInput,
  // SelectField,
  TextField
} from 'react-admin'
import JointPurchaseListExcelExportButton from './JointPurchaseListExcelExportButton'
import {parse, stringify} from 'querystring'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = ({basePath, props}) => (
  <CardActions>
    <JointPurchaseListExcelExportButton {...props} />
    <CreateButton basePath={basePath} />
  </CardActions>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="아이디" source="searchId" alwaysOn />
    <SearchInput label={'제목'} source="search" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
  </Filter>
)

const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)
export default withStyles(styles)(({classes, ...props}) => {
  const queryStr = () => {
    const {
      location: {search}
    } = props
    const query = parse(decodeURIComponent(search))
    return query.hasOwnProperty('?filter') ? JSON.parse(query['?filter']) : null
  }
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={true}>
      <Datagrid rowClick={(id, basePath) => `${basePath}/${id}/show?${stringify(queryStr())}`}>
        {/*<SelectField*/}
        {/*  source="type"*/}
        {/*  choices={[*/}
        {/*    {id: 'notice', name: '공지사항'},*/}
        {/*    {id: 'event', name: '이벤트'}*/}
        {/*  ]}*/}
        {/*/>*/}
        <TextField source="id" sortable={false} />
        <ThumbnailImageField source="titleImagePath" sortable={false} />
        <TextField source="title" sortable={false} />
        <NumberField source="totalReadCount" sortable={false} />
        {/*<NumberField source="readCount" sortable={false} />*/}
        <NumberField source="totalLikeCount" sortable={false} />
        {/*<NumberField source="likeCount" sortable={false} />*/}
        <NumberField source="totalCommentCount" sortable={false} />
        {/*<NumberField source="commentCount" sortable={false} />*/}
        <NumberField source="rolling_order" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <DateField source="startDate" sortable={false} />
        <DateField source="endDate" sortable={false} />
      </Datagrid>
    </List>
  )
})
